.App {
    width: 100%;
    padding-top:30px;
    justify-content: center;
    align-items: center;
    display: grid;
}

#views{
    font-size: 7px;
    grid-column: 1;
    grid-row: 2;
    justify-content: center;
    display: grid;
    margin-top: -10px;
}
.parent {
    display: inline;
    grid-template-columns: 150px ;
    grid-gap: 10px;
    list-style-type: none;
    text-align: center;
    text-decoration: none;
    margin-left: -40px;
}

a {
    text-decoration: none;
}

.privacy {
    height: 100%;
    overflow: hidden;
}
.privacy p {
    text-align: left !important;
}
.privacy-button {
    justify-content: center;
    text-align: center;
    color: black;
    background-color: lightgray;
    border-radius: 5px;
    width: 50%;
    align-items: center;
    display: grid;
    margin: 10px auto;
}