.code-block {
    transform: translate(0px, 10px);
    width: 50%;
    margin: 0px auto 20px auto;
    overflow: hidden;
}
.code-block__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    padding: 0 40px;
}
.code-block__container:before {
    content: '[';
    left: 0;
}
.code-block__container:after {
    content: ']';
    position: absolute;
    right: 0;
}
.code-block__container:after, .code-block__container:before {
    position: absolute;
    top: 0;
    color: #487b97;
    font-size: 42px;
    line-height: 40px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
}
.code-block__container__text {
    display: inline;
    float: left;
    margin: 0;
}
.code-block__container__list {
    margin-top: 0;
    padding: 0px;
    text-align: center;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 7s;
    animation-iteration-count: infinite;
}
.code-block__container__list__item {
    line-height: 40px;
    margin: 0;
}
.code-block__subtext {
    text-align: center;
}
@-webkit-keyframes opacity {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
@-webkit-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
    }
}
@-o-keyframes opacity {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
@-o-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
    }
}
@-moz-keyframes opacity {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
@-moz-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
    }
}
@keyframes opacity {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
@keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
    }
}
