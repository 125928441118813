@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

:root {
    --card-height: auto;
    --card-width: auto
}

.Card {
    width: 300px;
    height: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px black ;
    grid-column: 1;
    grid-row: 1;
}

 /** background: linear-gradient(to left, #3a738c, #c7c6ff)**/


.upper-container {
    height: 100px;
    background: linear-gradient(to left, #3a738c, #c7c6ff, #8ab5d9, #e0e5ff, #3a738c);
    background-size: 300% 300%; /* Größerer Bereich für fließende Bewegung */
    animation: smoothGradient 10s infinite; /* Längere Animation für weiche Übergänge */
}

@keyframes smoothGradient {
    0% {
        background-position: 0% 50%; /* Startposition */
    }
    50% {
        background-position: 100% 50%; /* Bewegung zur anderen Seite */
    }
    100% {
        background-position: 0% 50%; /* Zurück zur Startposition */
    }
}

.image-container img {
    background: white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 5px;
    transform: translate(95px, 45px);
    animation: flicker 60s infinite; /* Alle 60s einmal flackern */
}

@keyframes flicker {
    0%, 58%, 100% {
        filter: grayscale(0) brightness(1); /* Bild bleibt normal */
    }
    59% {
        filter: grayscale(1) brightness(0.5); /* Schwarz-Weiß und abgedunkelt für 1 Sekunde */
    }
    60% {
        filter: grayscale(0) brightness(1); /* Zurück zu normalen Farben */
    }
}


img {
    border: 2px solid #008080;
}
a img{
    border: none;
    padding: 10px;
}

.lower-container{
margin-top: 60px;
height: auto;
background: white;
text-align: center;
}
.lower-container h3 , h4 , p {
margin: 0;
padding: 0;
}
.lower-container h3 {
    color: #2f2d2e ;
    font-weight: 600;
    font-size: 1.5em;
 }

 .lower-container  .typing-effect {
    color: #008080;
    font-weight: 700;
    font-size: 0.9rem;
    
    /* Basisstil für den Text */
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid #008080; /* Erzeugt den blinkenden Cursor */
    animation: typing 6s steps(42) 1s forwards, blink 0.75s step-end infinite; /* 2 Animationen: Tippen und Blinken */
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 80%; /* Text wird bis zum Ende angezeigt */
    }
}

@keyframes blink {
    0%, 50% {
        border-color: transparent;
    }
    50%, 100% {
        border-color: #008080;
    }
}


.lower-container p {
    margin: 1rem;
    font-weight: 300;
    color: #2f2d2e;
    font-size: 0.9rem;
    text-align: center;
 }

button {
    margin: 1rem;
    font-weight: bold;
    background-color: white;
    border: 2px solid #008080;
    padding: 0.6rem;
}
button a {
    color: black;
}

.card-border {
    position: relative;
    border-radius: 6px;
}
.card-border::before {
    content: "";
    width: 104%;
    height: 102%;
    border-radius: 8px;
    background-image: linear-gradient(
            var(--rotate)
            , #5ddcff, #3a738c 43%, #c7c6ff);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2s linear infinite;
    opacity: 40%;
}

.card-border::after {
    transform: scale(0.8);
    filter: blur(calc(var(--card-height) / 6));
    transition: opacity .5s;
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }
    100% {
        --rotate: 360deg;
    }
}